export const sampleScript = `
  In the bustling television studio of Channel Pond News, there lived a most unusual amphibian named Telly. 
  Unlike his pond-dwelling cousins who spent their days catching flies and croaking at the moon, Telly had developed a rather remarkable talent: he could teleprompt like no other creature in the animal kingdom.
  It all started one rainy evening when Telly, seeking shelter from a storm, hopped through an open window at the station. 
  He landed right next to the teleprompter operator's desk, fascinated by the scrolling words on the screen. Night after night, he returned to watch the words dance across the monitor, until he began to understand the subtle art of pacing and timing.
  One fateful evening, the regular teleprompter operator called in sick.
  The studio was in chaos – until Telly stepped up to the challenge.
  Using his sticky tongue as a makeshift scrolling device and his bulbous eyes to carefully track the news anchor's speech patterns, he operated the teleprompter with unprecedented precision.
`

