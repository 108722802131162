import React, { useState, useEffect, useRef } from 'react';
import RemoteLinkRecordTeleprompterSlider from './RemoteLinkRecordTeleprompterSlider';
import RemoteLinkRecordTeleprompter from './RemoteLinkRecordTeleprompter';
import RemoteLinkRecordCameraPreview from './RemoteLinkRecordCameraPreview';
import { sampleScript } from './sampleScript';

const RemoteLinkRecordPage = () => {
  const [teleprompterPace, setTeleprompterPace] = useState(0.5);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isRecording, setIsRecording] = useState(false);
  const [teleprompterProgress, setTeleprompterProgress] = useState(0);
  const containerRef = useRef(null);
  const [isDraggingSlider, setIsDraggingSlider] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    };

    // Initial measurement
    updateDimensions();

    // Set up ResizeObserver for container
    const resizeObserver = new ResizeObserver(updateDimensions);
    resizeObserver.observe(containerRef.current);

    // Set up window resize listener
    window.addEventListener('resize', updateDimensions);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const handleStart = () => {
    setIsRecording(true);
  };

  const handleStop = () => {
    setIsRecording(false);
    setTeleprompterProgress(0);
  };

  const handleRestart = () => {
    setIsRecording(false);
    setTeleprompterProgress(0);
  };

  const isPreviewing = !isRecording && isDraggingSlider

  return (
    <div 
      ref={containerRef} 
      className="remoteLinkRecordPage"
    >
      
      {!isPreviewing &&
        <button 
          className='remoteLinkRecordPage-startStopButton'
          onClick={isRecording ? handleStop : handleStart}
        >
          <div className='remoteLinkRecordPage-startStopButton-inner'>
            {!isRecording && 
              <div className='remoteLinkRecordPage-startStopButton-inner-circle' />
            }
            {isRecording && 
              <div className='remoteLinkRecordPage-startStopButton-inner-square' />
            }
          </div>
        </button>
      }
      

      {isPreviewing &&
        <button className='remoteLinkRecordPage-previewingLabel'>
          Scroll Preview
        </button>
      }
      

      <button onClick={handleRestart}>Restart</button>
      <RemoteLinkRecordTeleprompterSlider 
        value={teleprompterPace}
        onChange={setTeleprompterPace}
        isRecording={isRecording}
        isDraggingSlider={isDraggingSlider}
        setIsDraggingSlider={setIsDraggingSlider}
      />
      <RemoteLinkRecordTeleprompter
        teleprompterPace={teleprompterPace}
        script={sampleScript}
        containerWidth={dimensions.width}
        containerHeight={dimensions.height}
        isRunning={isRecording}
        onProgressChange={setTeleprompterProgress}
        progress={teleprompterProgress}
        isDraggingSlider={isDraggingSlider}
      />
      
      <RemoteLinkRecordCameraPreview
        pageWidth={dimensions.width}
        pageHeight={dimensions.height}
      />
      
    </div>
  );
};

export default RemoteLinkRecordPage;