import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import Icon from '../Icon';
import VORecordModal from '../flow/VORecordModal';
import LibraryVoiceButton from '../flow/LibraryVoiceButton';
import AIVoicePreviewButton from '../flow/AIVoicePreviewButton';

////Component to record voiceover for voice match or select a library voice

const VoiceoverManager = ({
  hasVOSelected,
  selectedLibraryVoice,
  voiceMatchSampleUrl,
  showCaptions,
  setShowCaptions,
  handleSetSelectedLibraryVoice,
  handleSetVoiceMatchId,
  setVoiceMatchSampleUrl,
  handleGenerateSample,
  firstName,
  projectId,
  getQueryParams,
  mainCounterNumberStart
}) => {
  const [showVORecordModal, setShowVORecordModal] = useState(false);

  return (
    <>
      <div className="page-sectionHeader">
        <div className="page-sectionHeader-number">{mainCounterNumberStart}.</div>
        <div className="page-sectionHeader-text">AI Voiceover</div>
      </div>

      {!hasVOSelected && (
        <div className="page-voiceoverPreInstructions">
          You'll record your voice, then an AI "matched" voiceover will be generated to narrate the video.
        </div>
      )}

      <div className="page-voiceover">
        {selectedLibraryVoice && (
          <LibraryVoiceButton selectedLibraryVoice={selectedLibraryVoice} />
        )}

        {voiceMatchSampleUrl && !selectedLibraryVoice && (
          <AIVoicePreviewButton voiceMatchSampleUrl={voiceMatchSampleUrl} />
        )}

        {selectedLibraryVoice && (
          <button
            className="page-voiceover-openModalBtn page-voiceover-openModalBtn--edit"
            onClick={() => handleSetSelectedLibraryVoice(null)}
          >
            Clear Voice Selection
          </button>
        )}

        {!selectedLibraryVoice && (
          <Dialog.Root open={showVORecordModal} onOpenChange={setShowVORecordModal}>
            <Dialog.Trigger asChild>
              <button
                className={`page-voiceover-openModalBtn ${
                  hasVOSelected ? "page-voiceover-openModalBtn--edit" : "page-voiceover-openModalBtn--new"
                }`}
                onClick={() => setShowVORecordModal(true)}
              >
                {!hasVOSelected ? "Make your AI voiceover" : "Edit Voiceover"}
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="VOModalOverlay" />
              <Dialog.Content className="VOModal">
                <VORecordModal
                  firstName={firstName}
                  projectId={projectId}
                  email=""
                  setSelectedLibraryVoice={handleSetSelectedLibraryVoice}
                  selectedLibraryVoice={selectedLibraryVoice}
                  closeVORecordModal={() => setShowVORecordModal(false)}
                  queryParams={getQueryParams()}
                  setVoiceMatchId={handleSetVoiceMatchId}
                  setVoiceMatchSampleUrl={setVoiceMatchSampleUrl}
                  handleGenerateSample={handleGenerateSample}
                />
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        )}

        {hasVOSelected && (
          <div onClick={() => setShowCaptions(!showCaptions)} className="page-addCaptions">
            <div className="page-addCaptions-checkboxContainer">
              <div
                data-checked-state={showCaptions ? "checked" : "unchecked"}
                className="page-addCaptions-checkbox"
              >
                <Icon name="check" />
              </div>
            </div>
            <div className="page-addCaptions-label">Add subtitles to generated video</div>
          </div>
        )}
      </div>
    </>
  );
};

export default VoiceoverManager;