import { extractPictureUrl } from './extractPictureUrl'

async function generateVideo(projectId, queryParams) {
  try {
    const { slug, ...otherParams } = queryParams;
    const safebtoa = (str) => btoa(unescape(encodeURIComponent(str)));
    const params ={...otherParams}
    params.pictureUrl=encodeURIComponent(extractPictureUrl(window.location.href))
    const optionsBase64 = safebtoa(JSON.stringify(params));
    const url = `https://orch.yarndist.com/orchestrator/render/${projectId}?options=${optionsBase64}&&renderSegmentSizeSeconds=4&apiTarget=prod`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to generate video');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error generating video:', error);
    throw error;
  }
}


export { generateVideo };