import React, { useState } from 'react';

const EDGE_MARGIN = 70;
const ASPECT_RATIO = 16/9;

const ScriptCameraPreview = ({ videoRef, isRecording, containerHeight, containerWidth, isDisplayRecording, selectHideVideo }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const getExpandedDimensions = () => {
    const maxWidth = containerWidth - (2 * EDGE_MARGIN);
    const maxHeight = containerHeight - (2 * EDGE_MARGIN);
    
    // Calculate dimensions maintaining 16:9 aspect ratio
    const heightBasedOnWidth = maxWidth / ASPECT_RATIO;
    const widthBasedOnHeight = maxHeight * ASPECT_RATIO;
    
    if (heightBasedOnWidth <= maxHeight) {
      // Width is the limiting factor
      return {
        width: maxWidth,
        height: heightBasedOnWidth
      };
    } else {
      // Height is the limiting factor
      return {
        width: widthBasedOnHeight,
        height: maxHeight
      };
    }
  };

  const expandedDimensions = isExpanded ? getExpandedDimensions() : null;
  
  const previewStyle = isExpanded ? {    
    width: `${expandedDimensions.width}px`,
    height: `${expandedDimensions.height}px`,    
  } : {
    
  };

  return (
    <>
      <div 
        data-expanded-state={isExpanded ? 'expanded' : 'default'} 
        data-visibility-state={(isDisplayRecording || selectHideVideo) ? 'hidden' : 'visible'} 
        onClick={() => setIsExpanded(!isExpanded)} 
        className='videoRecordModal--script-previewContainer'        
      >
        <div className="videoRecordModal--script-preview" style={previewStyle}>
          <video 
            ref={videoRef} 
            autoPlay 
            playsInline 
            muted
            className="videoRecordModal--script-preview-video"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
      </div>
      {isExpanded && (
        <div 
          onClick={() => setIsExpanded(false)} 
          className='videoRecordModal--script-previewCollapseButton' 
        />
      )}
    </>
  );
};

export default ScriptCameraPreview;