import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DeliveryPage from '../components/deliveryPage/DeliveryPage';
import { fetchVariantByShareId, fetchRenderJobByShareId } from '../actions/fetchByShareId';

const TEST_SHARE_ID = '3c88462a40aa'; // Replace with your specific shareId

const getTestValues = () => ({
  muxAssetStatus: 'ready',
  muxPublicPlaybackId: 'y7HbxOpIZg01A7l7QhQq02r4YHRuM02KaaxGOVFFE8SRnw',
  s3Url: 'https://example.com/test-video.mp4'
});

const JunoDeliveryPageContainer = () => {
  const [variantData, setVariantData] = useState(null);
  const [renderJobStatus, setRenderJobStatus] = useState(null);
  const { shareId } = useParams();
  const pollingTimeout = useRef(null);

  useEffect(() => {
    const fetchVariantData = async () => {
      try {
        // For test shareId, set mock variant data
        if (shareId === TEST_SHARE_ID) {
          setVariantData({
            // Add your test variant data here
            id: 'test-variant',
            name: 'Test Variant'
          });
          return;
        }
        const response = await fetchVariantByShareId(shareId);
        setVariantData(response);
      } catch (error) {
        console.log(error);
      }
    };

    const pollRenderJobStatus = async () => {
      try {
        // For test shareId, set predefined values
        if (shareId === TEST_SHARE_ID) {
          setRenderJobStatus(getTestValues());
          return;
        }

        const response = await fetchRenderJobByShareId(shareId);
        setRenderJobStatus(response);
        if (response.muxAssetStatus !== 'ready') {
          pollingTimeout.current = setTimeout(pollRenderJobStatus, 2000);
        }
      } catch (error) {
        console.log(error);
        pollingTimeout.current = setTimeout(pollRenderJobStatus, 50000);
      }
    };

    pollRenderJobStatus();
    fetchVariantData();

    return () => {
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }
    };
  }, [shareId]);

  let muxPlaybackId;
  let muxAssetStatus;
  let s3Url;

  if (shareId === TEST_SHARE_ID) {
    const testValues = getTestValues();
    muxAssetStatus = testValues.muxAssetStatus;
    muxPlaybackId = testValues.muxPublicPlaybackId;
    s3Url = testValues.s3Url;
  } else if (renderJobStatus) {
    muxAssetStatus = renderJobStatus.muxAssetStatus;
    if (muxAssetStatus === 'ready') {
      muxPlaybackId = renderJobStatus.muxPublicPlaybackId;
      s3Url = renderJobStatus.s3Url;
    }
  }

  return (
    <div className="deliveryPageContainer">
      <DeliveryPage
        muxAssetStatus={muxAssetStatus}
        s3Url={s3Url}
        muxPlaybackId={muxPlaybackId}
        variantData={variantData}
        shopifySample={shareId === TEST_SHARE_ID}
      />
    </div>
  );
};

export default JunoDeliveryPageContainer;