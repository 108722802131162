import { post, fetch } from '../apiProd';
import { uploadToS3, uploadLargeFileToS3 } from './utils/uploadWebcamToS3';

const SIZE_THRESHOLD = 100 * 1024 * 1024;

export const createWebcamUpload = (sessionId, params) => {
  return post(`/creator/session/${sessionId}/webcam`, params);
};

export const markUploadComplete = (sessionId, captureId) => {
  return post(`/creator/session/${sessionId}/webcam/${captureId}/upload-complete`);
};

export const addWebcamToCreatorSession = ({
  blobUrl,
  captureId,
  sessionId,
  variableName,
  variableWebcamId,
  creatorSetAudioOnly
}) => {
  return (dispatch) => {
    return window.fetch(blobUrl)
      .then(res => res.blob())
      .then(blob => {
        return createWebcamUpload(sessionId, {
          captureId,
          variableWebcamId,
          contentType: blob.type,
          creatorSetAudioOnly
        }).then((response) => {
          console.log('response is')
          console.log(response)
          const {uploadURL} = response
          console.log('uploadUrl is',uploadURL)


          // Upload to S3 based on file size
          const uploadPromise = blob.size > SIZE_THRESHOLD
            ? uploadLargeFileToS3(blob, uploadURL)
            : uploadToS3(blob, uploadURL);

          return uploadPromise
            .then(() => markUploadComplete(sessionId, captureId))
            // .then(updateResponse => ({
            //   ...updateResponse,
            //   captureId,
            //   recordingId,
            //   key
            // }));
        });
      })
      .then(response => {
        
        return response;
      })
      .catch(error => {
        console.error('Error during webcam upload:', error);
        throw error;
      });
  };
};