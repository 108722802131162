const replaceVariablesInText = (text, variables) => {
  if(!text){
    return ''
  }

  // First, replace variables with their values or nothing if the variable is not provided
  let updatedText = text.replace(/{{(\w+)}}/g, (match, variable) => {
    return variables[variable] || '';
  });
  // Then, clean up any remaining commas and spaces
  updatedText = updatedText
    .replace(/,\s*,/g, ',') // Replace double commas with single comma
    .replace(/,\s*([,\.])/g, '$1') // Remove comma if followed by another comma or period
    .replace(/\s+/g, ' ') // Replace multiple spaces with single space
    .replace(/,\s*([^\w\s]|$)/g, '$1') // Remove comma if followed by punctuation or end of string
    .replace(/\s+([,\.])/g, '$1') // Remove space before comma or period
    .trim(); // Remove leading and trailing whitespace
  return updatedText;
};

export { replaceVariablesInText };