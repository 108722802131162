import * as Sentry from "@sentry/react";


const captureRenderTimeout = (eventData) => {
  Sentry.captureEvent({
    message: "video generation timeout",
    level: "error", 
    extra: eventData
  });
};

const captureProjectCreationFailed = (error) => {
 Sentry.captureException(error, {
    level: 'error',
    tags: { action: 'project_creation' },
    extra: {
      message: 'Project creation failed',
      errorDetails: error.toString(),
    }
  });
};



export { captureRenderTimeout,captureProjectCreationFailed };