import { useState, useEffect, useRef } from 'react';
import VideoRecordModalScriptCameraPreview from './VideoRecordModalScriptCameraPreview';
import VideoRecordModalScriptToolbar from './VideoRecordModalScriptToolbar';
import VideoRecordModalScriptTele from './VideoRecordModalScriptTele';
import { sampleScript } from '../../remoteLinkRecordPage/sampleScript';

const VideoRecordModalScript = ({
  videoRef,
  isRecording,
  onStartRecording,
  onStopRecording,
  onRestart,
  onOpenChange,
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  script,
  isReadyToRecord,
  isDisplayRecording,
  selectHideVideo,
  setSelectHideVideo
}) => {

  const [teleprompterPace, setTeleprompterPace] = useState(0.5);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });  
  const [teleprompterProgress, setTeleprompterProgress] = useState(0);
  const measureRef = useRef(null);
  const [isDraggingSlider, setIsDraggingSlider] = useState(false);

  useEffect(() => {
    if (!isRecording) {
      setTeleprompterProgress(0); // Reset progress when recording stops
    }
  }, [isRecording]);


  useEffect(() => {
    if (!measureRef.current) return;

    const updateDimensions = () => {
      if (measureRef.current) {
        setDimensions({
          width: measureRef.current.offsetWidth,
          height: measureRef.current.offsetHeight
        });
      }
    };

    // Initial measurement
    updateDimensions();

    // Set up ResizeObserver for container
    const resizeObserver = new ResizeObserver(updateDimensions);
    resizeObserver.observe(measureRef.current);

    // Set up window resize listener
    window.addEventListener('resize', updateDimensions);

    return () => {
      if (measureRef.current) {
        resizeObserver.unobserve(measureRef.current);
      }
      window.removeEventListener('resize', updateDimensions);
    };
  }, [measureRef]); // Add dependency array

    const isPreviewing = !isRecording && isDraggingSlider


  return (
    <>
      
      <VideoRecordModalScriptCameraPreview 
        isRecording={isRecording}
        isDisplayRecording={isDisplayRecording}
        videoRef={videoRef} 
        containerWidth={dimensions.width}
        containerHeight={dimensions.height}
        selectHideVideo={selectHideVideo}
      />

      
      <VideoRecordModalScriptToolbar 
        isRecording={isRecording}
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
        onRestart={onRestart}
        onOpenChange={onOpenChange}
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        activeMicrophone={activeMicrophone}
        setActiveMicrophone={setActiveMicrophone}
        cameras={cameras}
        microphones={microphones}

        teleprompterPace={teleprompterPace}
        setTeleprompterPace={setTeleprompterPace}        
        isDraggingSlider={isDraggingSlider}
        setIsDraggingSlider={setIsDraggingSlider}
        isReadyToRecord={isReadyToRecord}
        isDisplayRecording={isDisplayRecording}
        selectHideVideo={selectHideVideo}
        setSelectHideVideo={setSelectHideVideo}
      />

      <VideoRecordModalScriptTele
        teleprompterPace={teleprompterPace}
        script={script || ''}
        containerWidth={dimensions.width}
        containerHeight={dimensions.height}
        isRunning={isRecording}
        onProgressChange={setTeleprompterProgress}
        progress={teleprompterProgress}
        isDraggingSlider={isDraggingSlider}
        isDisplayRecording={isDisplayRecording}
      />
      

      <div ref={measureRef} className='videoRecordModal--script-measurer' />
    </>
  );
};

export default VideoRecordModalScript;