//temp hacky thing to handle the unencoded picture url in the request for metaprompter

function extractPictureUrl(url) {
  try {
    // Find the pictureUrl parameter start
    const pictureUrlStart = url.indexOf('pictureUrl=');
    if (pictureUrlStart === -1) return null;

    // Get everything after pictureUrl=
    const startIndex = pictureUrlStart + 'pictureUrl='.length;
    
    // Find the next parameter by looking for &firstName
    // This assumes firstName always comes after pictureUrl
    const endIndex = url.indexOf('&firstName', startIndex);
    if (endIndex === -1) return null;

    // Extract and decode the URL
    const pictureUrl = url.substring(startIndex, endIndex);
    return decodeURIComponent(pictureUrl);
  } catch (error) {
    console.error('Error parsing picture URL:', error);
    return null;
  }
}

export { extractPictureUrl };