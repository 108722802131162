import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
// import ProjectPageContainer from './ProjectPageContainer';

import ClayCreatorPageContainer from './ClayCreatorPageContainer'
import ClayDevCreatorPageContainer from './ClayDevCreatorPageContainer'
import GifPageContainer from './GifPageContainer'


import ClayOnboardingVideoContainer from './ClayOnboardingVideoContainer'
import CreatorPageContainer from './CreatorPageContainer'
import RemoteLinkRecordContainer from './RemoteLinkRecordContainer'

//////Demos
//juno
import JunoDeliveryPageContainer from './JunoDeliveryPageContainer' //Juno Sales Demo Video Delivery Page
import CampaignPageContainer from './campaigns/CampaignPageContainer'
import CampaignVariantPageContainer from './campaigns/CampaignVariantPageContainer'

//static pages
import ElevenLabsDemoPageContainer from './ElevenLabsDemoPageContainer'
import ShopifyDemoPageContainer from './ShopifyDemoPageContainer'




// import DevCampaignVariantPageContainer from './campaigns/DevCampaignVariantPageContainer'



const gifProjectIds=['709652615','452897519']
const ELEVEN_LABS_DEMO_PROJECT_ID="2144900467"

//some of the clay projects are in dev environment
//hubspot 350777700
//metapropter 293741495
//GMaps 321762070
//hubspot yarn edit 1783198200
//gmaps yarn edit 994585621
//claygent neon 1353136186


const DEV_PROJECT_IDS=['350777700','293741495','321762070','1783198200','994585621','1353136186']


const HomePageWrapperContainer = () => {
  const { customerName, projectNameAndId } = useParams();
  const projectId = projectNameAndId.split('-').pop(); // Assuming projectNameAndId format is "name-id"
  if (projectId === ELEVEN_LABS_DEMO_PROJECT_ID) {
    return <ElevenLabsDemoPageContainer />;
  } else if (gifProjectIds.includes(projectId)) {
    return <GifPageContainer />;
  } else {
    const isDev = DEV_PROJECT_IDS.includes(projectId)
    if(isDev){
      return <ClayDevCreatorPageContainer />;
    }else{
      return <ClayCreatorPageContainer />;
    }
    
  }
};


const AppContainer = () => {
  return (
    <div id="app">
      <Router>
        <Routes>
          <Route path="/record" element={<RemoteLinkRecordContainer />} />
          <Route path="/watch/:shareId" element={<ClayOnboardingVideoContainer />} />
          <Route path="/shopify/Future-Proofing-Retail-xj123jalsll2" element={<ShopifyDemoPageContainer />} />
          <Route path="/share/:shareId" element={<JunoDeliveryPageContainer />} />          
          {/*<Route path="/record" element={<DevCampaignVariantPageContainer />} />*/}
          <Route path="/campaign/:campaignId/:variantId" element={<CampaignVariantPageContainer />} />
          <Route path="/campaign/:campaignId" element={<CampaignPageContainer />} />
          <Route path="/collab/:projectNameAndId" element={<CreatorPageContainer />} />
          <Route path="/:customerName/:projectNameAndId" element={<HomePageWrapperContainer />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppContainer;