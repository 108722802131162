import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const VideoRecordModalScriptTele = ({ 
  script, 
  teleprompterPace, 
  containerHeight, 
  containerWidth,
  isRunning,
  onProgressChange,
  progress,
  isDraggingSlider,
  isDisplayRecording
}) => {
  // Constants
  // const MIN_LINES_PER_SEC = 0;  
  // const MID_LINES_PER_SEC = 0.8;  // Middle speed at pace 0.5
  // const MAX_LINES_PER_SEC = 1.6;

  const MIN_LINES_PER_SEC = 0;  
  const MID_LINES_PER_SEC = 0.8;  // Middle speed at pace 0.5
  const MAX_LINES_PER_SEC = 1.6;
  const LINE_HEIGHT = 36;
  const FONT_SIZE = 34;
  const SCRIPT_TITLE_HEIGHT = 60

  // State
  const [scrollOffset, setScrollOffset] = useState(0); 
  const [totalHeight, setTotalHeight] = useState(0);

  const containerRef = useRef(null);
  const requestRef = useRef();
  const lastTimestampRef = useRef(null);
  const lastPositionRef = useRef(0);

  const [springs, api] = useSpring(() => ({
    y: 0,
    config: {      
      tension: 170,
      friction: 24
    }
  }));

  useEffect(() => {
    if (containerRef.current) {
      const contentEl = containerRef.current.querySelector('.videoRecordModal--script-tele-content');
      if (contentEl) {
        setTotalHeight(contentEl.offsetHeight);
      }
    }
  }, [script]);

  useEffect(() => {
    const shouldRun = isRunning || (!isRunning && isDraggingSlider);
    
    if (!shouldRun) {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
        requestRef.current = null;
      }
      // Animate to 0 when stopping
      api.start({
        y: 0,
        from: { y: scrollOffset },
        immediate: false,
        onRest: () => {
          setScrollOffset(0);
          lastPositionRef.current = 0;
          lastTimestampRef.current = null;
          onProgressChange(0);
        }
      });
      return;
    }

    // Update spring immediately during active scrolling
    api.start({ y: scrollOffset, immediate: true });

    const animate = (timestamp) => {
      if (!lastTimestampRef.current) {
        lastTimestampRef.current = timestamp;
        lastPositionRef.current = scrollOffset;
      }

      let currentSpeed;
      if (teleprompterPace <= 0.5) {
        currentSpeed = MIN_LINES_PER_SEC + 
          (teleprompterPace / 0.5) * (MID_LINES_PER_SEC - MIN_LINES_PER_SEC);
      } else {
        currentSpeed = MID_LINES_PER_SEC + 
          ((teleprompterPace - 0.5) / 0.5) * (MAX_LINES_PER_SEC - MID_LINES_PER_SEC);
      }
      
      const pixelsPerSecond = currentSpeed * LINE_HEIGHT;
      const deltaTime = (timestamp - lastTimestampRef.current) / 1000;
      const deltaPixels = pixelsPerSecond * deltaTime;
      
      const newOffset = lastPositionRef.current - deltaPixels;
      const totalDistance = totalHeight - containerHeight;
      const currentProgress = Math.min(Math.abs(newOffset) / totalDistance, 1);

      onProgressChange(currentProgress);

      if (newOffset <= -totalHeight) {
        setScrollOffset(-totalHeight);
        onProgressChange(1);
        return;
      }

      setScrollOffset(newOffset);
      lastPositionRef.current = newOffset;
      lastTimestampRef.current = timestamp;
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isRunning, isDraggingSlider, totalHeight, containerHeight, scrollOffset, onProgressChange, teleprompterPace, api]);

  const containerStyle = {    
    overflow: 'hidden',    
    fontSize: `${FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT}px`,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  };

  const contentStyle = {
    willChange: 'transform',
    padding: '10px 10px 10px 10px', // Added 60px top padding
    boxSizing: 'border-box',
  };

  const paragraphs = script.split('\n').map((paragraph, index) => (
    <div className='videoRecordModal--script-tele-content-para' key={index}>
      {paragraph}
    </div>
  ));

  return (
    <>
      <div data-visibility-state={isDisplayRecording ? 'visible' : 'hidden'} className='videoRecordModal--script-recordProgressContainer'>
        <div className='videoRecordModal--script-recordProgress'>
          <div className='videoRecordModal--script-recordProgress-bar'>
            <div 
              style={{width: `${progress * 100}%`}} 
              className='videoRecordModal--script-recordProgress-bar-bar' 
            />
          </div>
        </div>
      </div>

      <div data-visibility-state={(!isRunning && isDraggingSlider) ? 'visible' : 'hidden'} className='videoRecordModal--script-previewingLabel'>
        Previewing Teleprompter Scroll Speed
      </div>
      

      <div ref={containerRef} className='videoRecordModal--script-tele' style={containerStyle}>
        <animated.div 
          className='videoRecordModal--script-tele-content' 
          style={{
            ...contentStyle,
            transform: springs.y.to(y => `translateY(${y}px)`)
          }}
        >
          <div style={{height: `${SCRIPT_TITLE_HEIGHT}px`}} data-visibility-state={isDisplayRecording ? 'hidden' : 'visible'} className='videoRecordModal--script-tele-title'>
            Voiceover Script
          </div>

          {paragraphs}
        </animated.div>
      </div>
      <div className='videoRecordModal--script-teleScrim' />
      <div className='videoRecordModal--script-teleBlock' />
    </>
  );
};

export default VideoRecordModalScriptTele;