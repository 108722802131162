import {fetch} from '../apiProd'


export const fetchProdProject = (id) => {
  return (dispatch) => {
    return fetch(`/creator/projects/${id}/details`)
      .then((response) => {
       dispatch({ type: 'FETCH_PROJECT_SUCESS' ,project:response});
        return response
      })
      .catch((error) => {
        return error
      })
  }
}


