import React, { useState } from 'react';
import DeliveryPageTitle from './DeliveryPageTitle';
import DeliveryPageTitleShopify from './DeliveryPageTitleShopify';
import DeliveryPageProcessing from './DeliveryPageProcessing';
import DeliveryPageCalendar from './DeliveryPageCalendar';
import VideoPlayer from './videoPlayer/VideoPlayer';
import { InlineWidget } from "react-calendly";

const DeliveryPage = ({variantData, muxAssetStatus, muxPlaybackId, s3Url, shopifySample}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const processing = !muxPlaybackId

  //const mp4DownloadUrl = "https://yarn-assets.s3.amazonaws.com/exports/1/1547770101_2024-10-22T20-01-00.250Z.mp4"
  const mp4DownloadUrl = s3Url
  const deliveryPageUrl = "https://www.bbc.com/" // later
  const calendlyUrl = "https://calendly.com/jasper-yarn/sample-intro-call"

  return (
    <div className="deliveryPageContainer">
      <div data-calendar-state={showCalendar ? "visible" : "hidden"} className="deliveryPage">
        <div className="deliveryPage-videoContainer">
          {processing &&
            <DeliveryPageProcessing />
          }      
          {!processing &&
            <VideoPlayer playbackId={muxPlaybackId} provider="mux" />
          }
        </div>
        
        <div data-visibility-state={showCalendar ? 'visible' : 'hidden'} className="deliveryPage-calendarContainer">
          
          <DeliveryPageCalendar 
            calendlyUrl={calendlyUrl}
          />
          
        </div>
        
        <div className='deliverPage-titleLeft' />              
        <div className="deliveryPage-titleWrapper">
          <div className="deliveryPage-titleCenter">
            {!shopifySample && 
              <DeliveryPageTitle 
                variantData={variantData}   
                processing={processing}       
                deliveryPageUrl={deliveryPageUrl}
                mp4DownloadUrl={mp4DownloadUrl}
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}              
              /> 
            }
            {shopifySample && 
              <DeliveryPageTitleShopify
                variantData={variantData}   
                processing={processing}       
                deliveryPageUrl={deliveryPageUrl}
                mp4DownloadUrl={mp4DownloadUrl}
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}              
              /> 
            }
          </div>
        </div>
        <div className='deliverPage-titleRight' />              
      </div>
    </div>
  );
};

export default DeliveryPage;