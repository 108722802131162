import { useState,useRef,useEffect } from 'react';
import { addWebcamToCreatorSession } from '../actions/webcam';
import { useDispatch } from 'react-redux';
import {randomID} from '../utils/randomID'

const loadVideoFromUrl = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error loading video:', error);
    return null;
  }
};



function useWebcams(variableWebcams, sessionId,addWebcamCaptureToSession,resetVideoGenState) {
  const dispatch = useDispatch();

  const [webcamStatuses, setWebcamStatuses] = useState(() => {
    return variableWebcams.reduce((acc, webcam) => ({
      ...acc,
      [webcam.id]: { state: 'waiting' }
    }), {});
  });


  const initializeWebcamStateFromSession = async (webcamCaptures) => {
  if (webcamCaptures && Object.keys(webcamCaptures).length > 0) {
    const newStatuses = variableWebcams.reduce(async (accPromise, webcam) => {
      const acc = await accPromise;
      const captures = webcamCaptures[webcam.id] || [];
      const mostRecentCapture = captures[captures.length - 1];
      
      if (mostRecentCapture) {
        const recordingBlob = await loadVideoFromUrl(`https://yarn-assets.s3.us-east-1.amazonaws.com/webcamRecordings/${mostRecentCapture.captureId}/recording_original.webm`);

        return {
          ...acc,
          [webcam.id]: { 
            state: 'complete',
            captureId: mostRecentCapture.captureId,
            recording: recordingBlob,
          }
        };
      }
      
      return {
        ...acc,
        [webcam.id]: { state: 'waiting' }
      };
    }, Promise.resolve({}));

    setWebcamStatuses(await newStatuses);
  }
};

  ///We finished a recording lets upload it
  //videoWasDisabled is true if creator chose to do audio only
  const onRecordingComplete = async (webcamId, seekableBlob,creatorSetAudioOnly) => {
    console.log('on recording complete')
    console.log('webcamid',webcamId)
    console.log(`creatorSetAudioOnly`,creatorSetAudioOnly)

    try {
      if(resetVideoGenState){
        resetVideoGenState()
      }

      const captureId=randomID()
      // Update status to uploading
      setWebcamStatuses(prev => ({
        ...prev,
        [webcamId]: { 
          ...prev[webcamId], 
          state: 'uploading',
          recording: seekableBlob,
          captureId:captureId,
          creatorSetAudioOnly:creatorSetAudioOnly
        }
      }));

      // Find the webcam variable data
      const webcam = variableWebcams.find(w => w.id === webcamId);

      if (!webcam) {
        throw new Error(`Webcam with id ${webcamId} not found`);
      }

      // Create blob URL
      const blobUrl = URL.createObjectURL(seekableBlob);

      // Upload the webcam recording
      const result = await dispatch(addWebcamToCreatorSession({
        blobUrl,
        captureId,
        sessionId,
        creatorSetAudioOnly
      }))

      await addWebcamCaptureToSession(webcamId, captureId, {
        status: 'complete'
      });

      console.log('result',result)
      URL.revokeObjectURL(blobUrl);
      // Update status to complete
      setWebcamStatuses(prev => ({
        ...prev,
        [webcamId]: { 
          ...prev[webcamId], 
          state: 'complete',
        }
      }));
    } catch (error) {
      console.error('Error handling webcam recording:', error);
      // Update status to error
      setWebcamStatuses(prev => ({
        ...prev,
        [webcamId]: { 
          ...prev[webcamId], 
          state: 'error',
          error: error.message
        }
      }));
    }
  };

  // Computed value for whether all webcams are complete
  // const allWebcamsComplete = Object.values(webcamStatuses).every(
  //   status => status.state === 'complete'
  // );

  const allWebcamsComplete = 
    Object.keys(webcamStatuses).length > 0 && // Ensure we have webcams
    Object.keys(webcamStatuses).length === variableWebcams.length && // Ensure all webcams are accounted for
    Object.values(webcamStatuses).every(status => status.state === 'complete');


  // Additional helper to check if any webcams are in error state
  const hasErrors = Object.values(webcamStatuses).some(
    status => status.state === 'error'
  );

  return {
    webcamStatuses,
    allWebcamsComplete,
    hasErrors,
    onRecordingComplete,
    initializeWebcamStateFromSession
  };
}

export default useWebcams;