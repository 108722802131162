import React from 'react';
import VideoRecordModalInstructionsPanel from './VideoRecordModalInstructionsPanel';
import VideoRecordModalInstructionsToolbar from './VideoRecordModalInstructionsToolbar';

const VideoRecordModalInstructions = ({
  videoRef,
  isRecording,
  onStartRecording,
  onStopRecording,
  onRestart,
  onOpenChange,
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  instructions,
  isReadyToRecord,
  isDisplayRecording,
  selectHideVideo,
  setSelectHideVideo
}) => {
  return (
    <>
      <div data-hide-recording={selectHideVideo ? true : false} className="videoRecordModal--instructions-center">
        <VideoRecordModalInstructionsPanel instructions={instructions} selectHideVideo={selectHideVideo}/>        
      

        <div className="videoRecordModal--instructions-preview">
          <video 
            ref={videoRef} 
            autoPlay 
            playsInline 
            muted
            className="videoRecordModal--instructions-preview-video"
          />
        </div>        
        
      </div>
      <VideoRecordModalInstructionsToolbar 
        isRecording={isRecording}
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
        onRestart={onRestart}
        onOpenChange={onOpenChange}
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        activeMicrophone={activeMicrophone}
        setActiveMicrophone={setActiveMicrophone}
        cameras={cameras}
        microphones={microphones}
        isReadyToRecord={isReadyToRecord}
        isDisplayRecording={isDisplayRecording}
        selectHideVideo={selectHideVideo}
        setSelectHideVideo={setSelectHideVideo}
      />
    </>
  );
};

export default VideoRecordModalInstructions;