import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createSession, fetchSession,updateSession } from '../actions/session';
import { saveSessionToLocalStorage, loadSessionFromLocalStorage } from '../utils/creatorPage/sessionStorage';
import backgroundMusicTracks from '../utils/backgroundMusicTracks';



const useSession = (projectId, paramsToSaveOnSession) => {
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(null);
  const [customizations, setCustomizations] = useState({});
  const [webcamCaptures, setWebcamCaptures] = useState({});

  const getDefaultBackgroundMusic = () => {
    return backgroundMusicTracks.sort((a, b) => a.title.localeCompare(b.title))[0]?.id;
  };

  const getInitialCustomizations = () => {
    return {
      selectedBGMusic: getDefaultBackgroundMusic(),
      selectedBGColor:0
    };
  };


  useEffect(() => {
    const initializeSession = async () => {
      const existingSession = loadSessionFromLocalStorage(projectId);
    //  console.log(existingSession )
      if (existingSession) {
        setCustomizations(existingSession.customizations || {}) //do this so dont get flash when reload the page
        setWebcamCaptures(existingSession.webcamCaptures || {});
        try {
          const sessionData = await dispatch(fetchSession(existingSession.id));
          setSessionId(existingSession.id);
          setCustomizations({
            ...getInitialCustomizations(),
            ...(existingSession.customizations || {})
          });
          setWebcamCaptures(sessionData.webcamCaptures || {});
          return;
        } catch (error) {
          console.error('Failed to fetch existing session:', error);
          // If fetch fails, we'll create a new session below
        }
      }
      try {        
        const requestUrl = window.location.href
        const session = await dispatch(createSession(projectId, paramsToSaveOnSession,requestUrl));
        saveSessionToLocalStorage(projectId, {
          id: session.id,
          customizations: {
            ...getInitialCustomizations(),
            ...(session.customizations || {})
          },
          webcamCaptures: session.webcamCaptures || {}
        });
        setSessionId(session.id);
        setCustomizations({
          ...getInitialCustomizations(),
          ...(session.customizations || {})
        });
        setWebcamCaptures(session.webcamCaptures || {});
      } catch (error) {
        console.error('Failed to create session:', error);
      }
    };
    if (projectId) {
      initializeSession();

    }
  }, [projectId, dispatch]);

  const updateCustomizations = async (newCustomizations) => {
    try {
      setCustomizations(prevCustomizations => {
        const mergedCustomizations = {
          ...prevCustomizations,
          ...newCustomizations
        };  
        dispatch(updateSession(sessionId, {
          customizations: mergedCustomizations,
          webcamCaptures
        }));
        saveSessionToLocalStorage(projectId, {
          id: sessionId,
          customizations: mergedCustomizations,
          webcamCaptures
        });
        return mergedCustomizations;
      });
    } catch (error) {
      console.error('Failed to update customizations:', error);
    }
  };

    const addWebcamCaptureToSession = async (webcamId, captureId, metadata = {}) => {
    try {
      setWebcamCaptures(prevCaptures => {
        const updatedCaptures = {
          ...prevCaptures,
          [webcamId]: [
            ...(prevCaptures[webcamId] || []),
            {
              captureId,
              timestamp: new Date().toISOString(),
              ...metadata
            }
          ]
        };
        dispatch(updateSession(sessionId, {
          customizations,
          webcamCaptures: updatedCaptures
        }));
        saveSessionToLocalStorage(projectId, {
          id: sessionId,
          customizations,
          webcamCaptures: updatedCaptures
        });
        return updatedCaptures;
      });
    } catch (error) {
      console.error('Failed to add webcam capture:', error);
      throw error;
    }
  };

  const getWebcamCaptures = (webcamId) => {
    return webcamCaptures[webcamId] || [];
  };

  const getAllWebcamCaptures = () => {
    return webcamCaptures;
  };

  return {
    sessionId,
    customizations,
    updateCustomizations,
    addWebcamCaptureToSession,
    webcamCaptures
  };
};

export default useSession;