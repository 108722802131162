import React, { useState, useEffect } from 'react';
import Icon from '../Icon';
import VideoPreviewPlayer from './videoPreviewPlayer/VideoPreviewPlayer';
import VideoRecordModal from './videoRecordModal/VideoRecordModal';

const VideoInput = (props) => {
  const {
    onRecordingComplete,
    status,
    hasInstructions,
    instructions,
    script
  }=props

  const [videoSrc, setVideoSrc] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectHideVideo, setSelectHideVideo] = useState(false);
  
  useEffect(() => {
    if (status?.recording) {
      const url = URL.createObjectURL(status.recording);
      setVideoSrc(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [status?.recording]);


  const handleVideoRecorded = (seekableBlob) => {
    setIsModalOpen(false);
    if(onRecordingComplete){
      onRecordingComplete(seekableBlob,selectHideVideo);
    }
  };

  const handleReRecord = () => {    
    setIsModalOpen(true);
  };

  // Cleanup URL when component unmounts
  useEffect(() => {
    return () => {
      if (videoSrc) {
        URL.revokeObjectURL(videoSrc);
      }
    };
  }, [videoSrc]);
  
  return (
    <>
      {videoSrc ? (
        <>
          <div className='page-videoPreview'>
            <div data-media-type={selectHideVideo ? 'audio' : 'video'} className='page-videoPreview-previewContainer'>
              <VideoPreviewPlayer url={videoSrc} selectHideVideo={selectHideVideo} />
            </div>
          </div>

          <button 
            onClick={() => setIsModalOpen(true)} 
            className='page-voiceover-openModalBtn page-voiceover-openModalBtn--new page-voiceover-openModalBtn--rerecord'
          >
            Re-record
          </button> 

        </>
      ) : (
        <div className='page-voiceover-newWebcamContainer'>
          <button 
            onClick={() => setIsModalOpen(true)} 
            className='page-voiceover-openModalBtn page-voiceover-openModalBtn--new page-voiceover-openModalBtn--new--webcam'
          >
            Record Video
          </button> 
        </div>
      )}       
      
      <VideoRecordModal 
        isOpen={isModalOpen}
        onOpenChange={setIsModalOpen}
        onVideoRecorded={handleVideoRecorded}
        instructions={instructions}
        script={script}
        selectHideVideo={selectHideVideo}
        setSelectHideVideo={setSelectHideVideo}
        // type="instructions"
        type={hasInstructions?'instructions':'script'}
      />
    </>
  );
};

export default VideoInput;