import React, { useState } from 'react';

const RemoteLinkRecordCameraPreview = ({ 
  pageWidth, 
  pageHeight,
  
}) => {

  const initialAspectRatio = 3/4
  const expandedAspectRatio = 16/9
  const initialHeight = 400
  const screenEdgePadding = 50

  const [isExpanded, setIsExpanded] = useState(false);
  
  // Calculate initial dimensions
  const initialWidth = initialHeight * initialAspectRatio;
  
  // Calculate maximum possible dimensions for expanded state
  const maxWidth = pageWidth - (screenEdgePadding * 2);
  const maxHeight = pageHeight - (screenEdgePadding * 2);
  
  // Calculate expanded dimensions while maintaining aspect ratio
  const expandedWidth = Math.min(maxWidth, maxHeight * expandedAspectRatio);
  const expandedHeight = expandedWidth / expandedAspectRatio;
  
  const style = {
    width: isExpanded ? expandedWidth : initialWidth,
    height: isExpanded ? expandedHeight : initialHeight,
    position: 'fixed',
    backgroundColor: 'red',
    borderRadius: '8px',
    cursor: 'pointer',
    zIndex: 500,
    ...(!isExpanded ? {
      // Collapsed state - middle right
      right: '30px',
      top: '50%',
      transform: 'translate(0, -50%)'
    } : {
      // Expanded state - center of screen
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    })
  };

  return (
    <div
      style={style}
      className=''
      onClick={() => setIsExpanded(!isExpanded)}
    />

  );
};

export default RemoteLinkRecordCameraPreview;