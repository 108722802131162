import { post,fetch } from '../apiProd';
import axios from 'axios';
import {randomID} from '../utils/randomID'


export function fetchExistingVoiceMatch(pictureUrl) {
  return async (dispatch) => {
    try {
      const response = await fetch(`/voicematch?pictureUrl=${pictureUrl}`);
      return response;
    } catch (error) {
      console.error('Error fetching vm:', error)
      throw error;
    }
  };
}

export const submitVoiceMatchSample = (projectId,email,queryParams, audioBlob) => {
  return async (dispatch) => {
    try {
      const sampleId = randomID();
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      const base64Audio = await new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result.split(',')[1]); // Remove the "data:audio/mp3;base64," part
        };
      });

      const requestBody = {
        project_id: projectId,
        email:email,
        query_params:queryParams,
        request_url: window.location.href,
        sample_id: sampleId,
        audio_data: base64Audio
      };

      const response = await post('/voicematch', requestBody);
      return { success: true,voiceId:response.voiceId};
    } catch (error) {
      console.error('Error uploading voice sample:', error);
      dispatch({ 
        type: 'SUBMIT_VOICE_MATCH_SAMPLE_FAILURE',
        payload: error.toString()
      });
      return { success: false, error: error.toString() };
    }
  };
};






//takes voiceid projectpackground and others

export const generateProjectWithOptions = (projectId,voiceSettings,bgColorOptions,bgMusicTrack,queryParams,showCaptions,isProjectNeon) => {
  return async (dispatch) => {
    try {
      const newProjectId=randomID() 
      const requestBody={
        newProjectId:newProjectId,
        voiceSettings:voiceSettings,
        bgColorOptions:bgColorOptions,
        backgroundMusicTrack:bgMusicTrack,
        queryParams:queryParams,
        showCaptions:showCaptions,
        isProjectNeon:isProjectNeon
      }
      const response = await post(`/projects/${projectId}/options`, requestBody);

      return response
    } catch (error) {
      console.error('Error uploading voice sample:', error);
      dispatch({ 
        type: 'SUBMIT_VOICE_MATCH_SAMPLE_FAILURE',
        payload: error.toString()
      });
      return { success: false, error: error.toString() };
    }
  };
};




