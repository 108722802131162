import React, {useState} from 'react'
import Icon from '../../Icon';
import ReactSlider from 'react-slider';

function VideoPreviewPlayerControls({    
    playing,
    onPlayPauseClick,
    progress,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    duration,
    showControls
  }) {


  return (
    <>

    <div data-visible-state={showControls ? true : false} className='page-videoPreviewPlayer-controls'>
                  
      <button onClick={onPlayPauseClick} className='page-videoPreviewPlayer-controls-playPauseBtn'>
        {playing && 
          <Icon name='playbackPause' />
        }
        {!playing && 
          <Icon name='playbackPlay' />
        }
      </button>

      <div className='page-videoPreviewPlayer-controls-seekBar'>
        <ReactSlider
          className="page-videoPreviewPlayer-controls-seekBar-seekBar"
          thumbClassName="page-videoPreviewPlayer-controls-seekBar-seekBar-thumb"
          trackClassName="page-videoPreviewPlayer-controls-seekBar-seekBar-track"                  
          renderThumb={(props, state) => {
              const { key, ...restProps } = props;
              return <div key={key} className='page-videoPreviewPlayer-controls-seekBar-thumb-thumb' {...restProps}></div>;
            }}        
          value={progress}
          min={0}
          max={0.999999}
          step={0.0001}
          onAfterChange={onSeekMouseUp}
          onBeforeChange={onSeekMouseDown}
          onChange={onSeek}
        />  

      </div>

    </div>
          
    

    </>
  );
}

export default VideoPreviewPlayerControls;