import React, { useState, useEffect,useRef } from 'react';
import RemoteLinkRecordPage from '../components/remoteLinkRecordPage/RemoteLinkRecordPage';

const RemoteLinkRecordContainer = () => {
  
  return (
    <>
        <RemoteLinkRecordPage
          
      />   
    </>
  );
};

export default RemoteLinkRecordContainer;