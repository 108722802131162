import React, {useRef, useState, useEffect} from 'react';
import Icon from '../../Icon';
import ReactPlayer from 'react-player/file';
import VideoPreviewPlayerControls from './VideoPreviewPlayerControls';
import VideoPreviewPlayerPreControls from './VideoPreviewPlayerPreControls'

function VideoPreviewPlayer({url,selectHideVideo, exampleVideo}) {

  const playerRef = useRef(null);    

  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0); // Between 0 and 1
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [lastPlayPauseChange, setLastPlayPauseChange] = useState(Date.now());
  const [showPreControls, setShowPreControls] = useState(true);

  const handlePlay = () => {
    setPlaying(true);
    setHasPlayed(true);
    setLastPlayPauseChange(Date.now());    
    setShowPreControls(false);
  };

  const handlePause = () => {
    setPlaying(false);
    setLastPlayPauseChange(Date.now());
  };

  // const handleEnded = () => {
  //   setPlaying(false);
  //   setShowPreControls(true);
  //   setProgress(0);
  //   setProgressSeconds(0);
  // };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

   // Add progress threshold for end detection
  const END_THRESHOLD = 0.99;

  // Modify handleProgress to also check for end
  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
      setProgressSeconds(state.playedSeconds);
      
      // Check if we're at the end
      if (state.played >= END_THRESHOLD) {
        handleEnded();
      }
    }
  };

  const handleEnded = () => {
    setPlaying(false);
    setShowPreControls(true);
    
    // Add slight delay before resetting progress
    setTimeout(() => {
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
      setProgress(0);
      setProgressSeconds(0);
    }, 50);
  };

  // Add effect to monitor and handle video completion
  useEffect(() => {
    if (progress >= END_THRESHOLD && playing) {
      handleEnded();
    }
  }, [progress, playing]);

  const handleSeekChange = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);    
  };

  const handleSeekMouseUp = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
    setSeeking(false);    
  };

  // Mouse States  
  // const [mouseMovedAt, setMouseMovedAt] = useState(null);

  // const handleMouseMove = () => {
  //   setMouseMovedAt(Date.now());
  // };

  // const isShortMouseMove = mouseMovedAt && (Date.now() - mouseMovedAt < 2000);
  // const isLongMouseMove = mouseMovedAt && (Date.now() - mouseMovedAt < 5000);

  // useEffect(() => {
  //   let timer;
  //   if (mouseMovedAt) {
  //     timer = setTimeout(() => {
  //       setMouseMovedAt(null);
  //     }, 5000);
  //   }
  //   return () => {
  //     if (timer) clearTimeout(timer);
  //   };
  // }, [mouseMovedAt]);

  // const shouldHideControls = () => {
  //   const timeSinceLastPlayPauseChange = Date.now() - lastPlayPauseChange;
  //   return !isShortMouseMove && playing && timeSinceLastPlayPauseChange > 2000;
  // };

  // const hideControls = shouldHideControls();
  // const hidePointer = !isLongMouseMove && playing

  const containerRef = useRef(null);
  

  return (
    <div 
      ref={containerRef}
      className='page-videoPreviewPlayer'      
      data-media-type={selectHideVideo ? 'audio' : 'video'}
    >
      

      <ReactPlayer
        ref={playerRef}
        className='page-videoPreviewPlayer-video'
        width='100%'
        height='100%'
        // url={playbackUrl}
        //url={'https://yarn-assets-dev.s3.us-east-1.amazonaws.com/ss-renders/final-282747006-1733346270035.mp4'}
        url={url}
        playing={playing}        
        progressInterval={20}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onDuration={handleDuration}
        onProgress={handleProgress}                
        controls={false}
      />


      {showPreControls && !selectHideVideo &&
        <VideoPreviewPlayerPreControls 
          duration={duration}
          exampleVideo={exampleVideo}
        />
      }
            

      {(!showPreControls || progress > 0 || selectHideVideo) && duration > 0 && 
        <VideoPreviewPlayerControls           
          showControls
          playing={playing}
          onPlayPauseClick={() => {
            setPlaying(!playing);
            setLastPlayPauseChange(Date.now());
          }}
          progress={progress}
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}
          duration={duration}          
        />
      }      

      <button onClick={() => setPlaying(!playing)} className='videoPlayer-bgPlayPauseBtn' />

    </div>
  );
}

export default VideoPreviewPlayer;