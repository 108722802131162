import React, { useRef } from 'react';
import Icon from '../Icon'

const RemoteLinkRecordTeleprompterSlider = ({ 
  value, 
  onChange, 
  isRecording, 
  isDraggingSlider, 
  setIsDraggingSlider 
}) => {
  const sliderWidth = 640;
  const sliderThumbWidth = 240;
  const sliderRef = useRef(null);
  const thumbRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDraggingSlider(true);
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const startX = e.clientX;
    const currentPixelOffset = value * (sliderWidth - sliderThumbWidth);

    const onMouseMove = (moveEvent) => {
      const delta = moveEvent.clientX - startX;
      let newPixelPosition = currentPixelOffset + delta;
      
      if (newPixelPosition < 0) newPixelPosition = 0;
      if (newPixelPosition > sliderWidth - sliderThumbWidth) {
        newPixelPosition = sliderWidth - sliderThumbWidth;
      }
      
      const newValue = newPixelPosition / (sliderWidth - sliderThumbWidth);
      onChange(newValue);
    };

    const onMouseUp = () => {
      setIsDraggingSlider(false);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const thumbLeft = value * (sliderWidth - sliderThumbWidth);
  const edgeIconWidth = 50
  const edgeLabelBuffer = 100 // for labels
  const fullEdgeIconSpace = edgeIconWidth + edgeLabelBuffer
  const edgeIconLeftSpace = thumbLeft > fullEdgeIconSpace
  const edgeIconRightSpace = sliderWidth - (thumbLeft + sliderThumbWidth) > fullEdgeIconSpace

  return (
    <div
      ref={sliderRef}
      style={{        
        width: `${sliderWidth}px`,        
      }}
      data-dragging-state={isDraggingSlider ? true : false}
      data-recording-state={isRecording ? true : false}
      className="remoteLinkRecord-teleprompterSlider"
    >
      <div className="remoteLinkRecord-teleprompterSlider-edgeLabel remoteLinkRecord-teleprompterSlider-edgeLabel--left">
        Slower
      </div>
      <div className="remoteLinkRecord-teleprompterSlider-edgeLabel remoteLinkRecord-teleprompterSlider-edgeLabel--right">
        Faster
      </div>
      <button
        ref={thumbRef}
        style={{          
          left: `${thumbLeft}px`,
          width: `${sliderThumbWidth}px`,          
          cursor: isDraggingSlider ? 'grabbing' : 'grab'
        }}
        className='remoteLinkRecord-teleprompterSlider-thumb'
        onMouseDown={handleMouseDown}
      >
        <Icon name='teleprompter' />
        <div data-space-state={edgeIconLeftSpace ? 'true' : 'false'} style={{width: `${edgeIconWidth}px`}} className='remoteLinkRecord-teleprompterSlider-thumb-edgeIcon remoteLinkRecord-teleprompterSlider-thumb-edgeIcon--left'>
          <Icon name='rewind' />
        </div>
        <div data-space-state={edgeIconRightSpace ? 'true' : 'false'}  style={{width: `${edgeIconWidth}px`}}  className='remoteLinkRecord-teleprompterSlider-thumb-edgeIcon remoteLinkRecord-teleprompterSlider-thumb-edgeIcon--right'>
          <Icon name='fastForward' />
        </div>
      </button>
    </div>
  );
};

export default RemoteLinkRecordTeleprompterSlider;