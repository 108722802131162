// src/hooks/useVoiceSelection.js
import { useState, useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSpeech } from '../actions/textToSpeechProd';
import { fetchExistingVoiceMatch } from '../actions/voiceMatch';
import { VOICE_MATCH_SAMPLE_TEXT } from '../utils/creatorPage/clayCreatorPageConfigs';
import { extractPictureUrl } from '../utils/creatorPage/extractPictureUrl';

const useVoiceSelection = (projectId) => {
  const dispatch = useDispatch();
  const location = useLocation(); // Access location inside the hook
  const [voiceMatchId, setVoiceMatchId] = useState(null);
  const [selectedLibraryVoice, setSelectedLibraryVoice] = useState(null);
  const [voiceMatchSampleUrl, setVoiceMatchSampleUrl] = useState(null);
  const [loadingVoiceMatch, setLoadingVoiceMatch] = useState(false);

  // Extract pictureUrl from the current URL
  //const pictureUrl = extractPictureUrl(window.location.href); //this is the old one for non encoded url

  const queryParams = new URLSearchParams(location.search);
  const pictureUrl = queryParams.get('pictureUrl');

 
  // Handler to set Voice Match ID and clear selected library voice if necessary
  const handleSetVoiceMatchId = (newVoiceMatchId) => {
    setVoiceMatchId(newVoiceMatchId);
    if (newVoiceMatchId !== null) {
      setSelectedLibraryVoice(null);
    }
  };

  // Handler to set Selected Library Voice and clear Voice Match ID if necessary
  const handleSetSelectedLibraryVoice = (newSelectedLibraryVoice) => {
    setSelectedLibraryVoice(newSelectedLibraryVoice);
    if (newSelectedLibraryVoice !== null) {
      setVoiceMatchId(null);
      setVoiceMatchSampleUrl(null);
    }
  };

  // Function to generate a sample audio URL based on the voice ID
  const handleGenerateSample = async (voiceId) => {
    try {
      const audioUrl = await dispatch(fetchSpeech(VOICE_MATCH_SAMPLE_TEXT, voiceId));
      setVoiceMatchSampleUrl(audioUrl);
    } catch (error) {
      console.error('Error generating speech:', error);
    }
  };

  // Effect to fetch existing voice match when pictureUrl is available
  useEffect(() => {
    const fetchVoiceMatch = async () => {
      if (pictureUrl) {
        setLoadingVoiceMatch(true);
        try {
          const response = await dispatch(fetchExistingVoiceMatch(pictureUrl));
          if (response && response.enhanced_voice_id) {
            handleSetVoiceMatchId(response.enhanced_voice_id);
            await handleGenerateSample(response.enhanced_voice_id);
          }
        } catch (error) {
          console.error('Error fetching existing voice match:', error);
        } finally {
          setLoadingVoiceMatch(false);
        }
      }
    };

    fetchVoiceMatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pictureUrl, projectId]);

  return {
    voiceMatchId,
    selectedLibraryVoice,
    voiceMatchSampleUrl,
    handleSetVoiceMatchId,
    handleSetSelectedLibraryVoice,
    handleGenerateSample,
    setVoiceMatchSampleUrl,
    loadingVoiceMatch,
  };
};

export default useVoiceSelection;
