import React, { useState } from 'react';
import VideoInput from '../flow/VideoInput';

const WebcamManager = ({
  variableWebcams,
  webcamStatuses,
  onRecordingComplete,
  mainCounterNumberStart
}) => {
  return (
    <>
      {variableWebcams.map((webcam, index) => {
        const status = webcamStatuses[webcam.id] || {state: 'waiting'};
        let headerLabel = 'Add a webcam recording'
        if(webcam.pageLabel){
          headerLabel = webcam.pageLabel
        }
        return (
          <>
            <div className="page-sectionHeader">
              <div className="page-sectionHeader-number">{mainCounterNumberStart + index}.</div>
              <div className="page-sectionHeader-text">{headerLabel}</div>
            </div>
            <div key={webcam.id}  className='page-videoInputContainer'>
              {/* {status.state} */}
              <VideoInput
                status={status}
                hasInstructions={webcam.hasInstructions}
                script={webcam.script}
                instructions={webcam.instructions}
                webcamDetails={webcam}
                onRecordingComplete={(seekableBlob,creatorSetAudioOnly)=>{onRecordingComplete(webcam.id,seekableBlob,creatorSetAudioOnly)}}
              />              
            </div>
          </>
        );
      })}
      <br/>
    </>
  );
};

export default WebcamManager;